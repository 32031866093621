import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import '../../styles/cta.css';
import Top from '../../img/cta/cta-top.png';

import CtaLeft from '../../img/cta/cta_left.png';
import CtaRight from '../../img/cta/cta_right.png';


const CTA = () => {
  return (
    <>
      <div className='shape-top'>
        <img src={Top} alt='Shape Top' className='img-fluid' />
      </div>
      <MDBContainer fluid className='cta-section'>
        <div className='cta-images'>
          <img src={CtaLeft} alt='Left Decoration' className='cta-image-left' />
          <img src={CtaRight} alt='Right Decoration' className='cta-image-right' />
        </div>
        <MDBRow className='justify-content-center'>
          <MDBCol md='8' className='text-center'>
            <h2>O que você está esperando?</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lab.</p>
            <MDBBtn className='cta-btn' href='#produtos'>Ver Produtos</MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default CTA;
