import React, { useState, useEffect, useRef } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from 'mdb-react-ui-kit';
import '../../styles/contato.css';

const Contato = () => {
  const [step, setStep] = useState(1);  // Estado para controlar o passo do formulário
  const [formHeight, setFormHeight] = useState('auto'); // Estado para controlar a altura do formulário
  const formRef = useRef(null); // Referência ao formulário para medir a altura

  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    mensagem: '',
    newsletter: false,
    outraInfo: ''
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    setStep(2);
  };

  const handlePrevStep = (e) => {
    e.preventDefault();
    setStep(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Dados enviados:', formData);
    // Aqui você pode enviar os dados para o seu backend ou API
  };

  useEffect(() => {
    if (formRef.current) {
      setFormHeight(formRef.current.scrollHeight);
    }
  }, [step]);

  return (
    <MDBContainer fluid className='contato-section'>
      <MDBRow className='contato-wrapper'>
        <MDBCol md='4' className='text-left'>
          <h2>Entre em Contato</h2>
          <p>Preencha o formulário ao lado para entrar em contato conosco. Estamos ansiosos para ouvir de você!</p>
        </MDBCol>
        <MDBCol md='5'>
          <div
            className='contato-form-wrapper'
            style={{ height: formHeight }}
            ref={formRef}
          >
            <div className={`form-step ${step === 1 ? 'active' : 'inactive'}`}>
              <form onSubmit={handleNextStep}>
                <MDBInput
                  label='Seu nome'
                  name='nome'
                  value={formData.nome}
                  onChange={handleInputChange}
                  className='mb-4'
                />
                <MDBInput
                  label='Seu email'
                  name='email'
                  value={formData.email}
                  onChange={handleInputChange}
                  className='mb-4'
                />
                <MDBInput
                  type='textarea'
                  label='Sua mensagem'
                  name='mensagem'
                  value={formData.mensagem}
                  onChange={handleInputChange}
                  className='mb-4'
                />
                <MDBBtn className='contato-btn' type='submit'>Próxima Etapa</MDBBtn>
              </form>
            </div>
            <div className={`form-step ${step === 2 ? 'active' : 'inactive'}`}>
              <form onSubmit={handleSubmit}>
                <MDBInput
                  label='Outras informações'
                  name='outraInfo'
                  value={formData.outraInfo}
                  onChange={handleInputChange}
                  className='mb-4'
                  type='textarea'
                  rows={3}
                />
                <div className='form-check mb-4'>
                  <input
                    type='checkbox'
                    id='newsletter'
                    name='newsletter'
                    checked={formData.newsletter}
                    onChange={handleInputChange}
                    className='form-check-input'
                  />
                  <label className='form-check-label' htmlFor='newsletter'>
                    Inscrever-se na newsletter
                  </label>
                </div>
                <MDBBtn color='secondary' onClick={handlePrevStep} className='ml-2 contato-btn-back'>
                  Voltar
                </MDBBtn>
                <MDBBtn className='contato-btn' type='submit'>Enviar</MDBBtn>
              </form>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default Contato;
