import React from 'react';
import Slider from "react-slick";
import { MDBContainer, MDBCol } from 'mdb-react-ui-kit';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../styles/produto.css';

const BottomSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <MDBContainer className="py-5">
      <Slider {...settings}>
        <MDBCol className="mb-4 d-flex justify-content-center">
          <div className="carousel-image large-image"></div>
        </MDBCol>
        <MDBCol className="mb-4 d-flex justify-content-center">
          <div className="carousel-image small-image"></div>
        </MDBCol>
        <MDBCol className="mb-4 d-flex justify-content-center">
          <div className="carousel-image small-image"></div>
        </MDBCol>
        <MDBCol className="mb-4 d-flex justify-content-center">
          <div className="carousel-image small-image"></div>
        </MDBCol>
      </Slider>
    </MDBContainer>
  );
};

export default BottomSection;
