import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import '../styles/gallery.css';  // Certifique-se de criar esse arquivo CSS
import Footer from '../components/footer'
import Navbar from '../components/navbar'

const Gallery = () => {
  const images = [
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300'
  ];

  return (
    <>
      <Navbar />
      <MDBContainer className="gallery-container py-5">
        <h2 className="text-center mb-5">Galeria</h2>
        <MDBRow className="gallery-grid">
          {images.map((src, index) => (
            <MDBCol key={index} md="3" className="mb-4 d-flex justify-content-center">
              <div className="gallery-item">
                <img src={src} alt={`Gallery Item ${index + 1}`} className="img-fluid gallery-image" />
              </div>
            </MDBCol>
          ))}
        </MDBRow>
      </MDBContainer>
      <Footer />
    </>
  );
};

export default Gallery;
