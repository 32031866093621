import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBBtn,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import "../../styles/produtos.css";
import Top from "../../img/index/produtos-top.png";
import Bot from "../../img/index/produtos-bot.png";

const Produtos = () => {
  const [activeTab, setActiveTab] = React.useState("all");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const produtosData = [
    {
      id: 1,
      title: "Produto Popular 1",
      category: "Popular",
      image: "cta_right.png",
    },
    {
      id: 2,
      title: "Produto Popular 2",
      category: "Popular",
      image: "cta_right.png",
    },
    {
      id: 3,
      title: "Produto Popular 3",
      category: "Popular",
      image: "cta_right.png",
    },
    {
      id: 4,
      title: "Produto Promocional 1",
      category: "Promocional",
      image: "cta_right.png",
    },
    {
      id: 5,
      title: "Produto Promocional 2",
      category: "Promocional",
      image: "cta_right.png",
    },
    {
      id: 6,
      title: "Produto Promocional 3",
      category: "Promocional",
      image: "cta_right.png",
    },
    {
      id: 7,
      title: "Produto Gráfica 1",
      category: "Gráfica",
      image: "cta_right.png",
    },
    {
      id: 8,
      title: "Produto Gráfica 2",
      category: "Gráfica",
      image: "cta_right.png",
    },
    {
      id: 9,
      title: "Produto Gráfica 3",
      category: "Gráfica",
      image: "cta_right.png",
    },
    {
      id: 10,
      title: "Produto MPDV 1",
      category: "MPDV",
      image: "cta_right.png",
    },
    {
      id: 11,
      title: "Produto MPDV 2",
      category: "MPDV",
      image: "cta_right.png",
    },
    {
      id: 12,
      title: "Produto MPDV 3",
      category: "MPDV",
      image: "cta_right.png",
    },
  ];

  const filteredProducts =
    activeTab === "all"
      ? produtosData
      : produtosData.filter((product) => product.category === activeTab);

  return (
    <>
      {/* Shape Superior */}
      <div className="shape-top">
        <img src={Top} alt="Shape Top" className="img-fluid" />
      </div>

      {/* Seção de Produtos */}
      <MDBContainer fluid className="produtos-section">
        <h2 className="text-center my-4">Produtos</h2>
        <h4 className="text-center mb-4">Nossos Produtos em Destaque</h4>

        <MDBTabs pills className="mb-3 justify-content-center">
          <MDBTabsItem>
            <MDBTabsLink
              className="produtos-tab"
              onClick={() => handleTabClick("all")}
              active={activeTab === "all"}
            >
              Todos
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="produtos-tab"
              onClick={() => handleTabClick("Popular")}
              active={activeTab === "Popular"}
            >
              Popular
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="produtos-tab"
              onClick={() => handleTabClick("Promocional")}
              active={activeTab === "Promocional"}
            >
              Promocional
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="produtos-tab"
              onClick={() => handleTabClick("Gráfica")}
              active={activeTab === "Gráfica"}
            >
              Gráfica
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink
              className="produtos-tab"
              onClick={() => handleTabClick("MPDV")}
              active={activeTab === "MPDV"}
            >
              MPDV
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

        <MDBCol className="card-pai">
          <MDBRow className="card-row">
            {filteredProducts.map((produto) => (
              <MDBCol md="4" key={produto.id} className="product-col">
                <MDBCard className="produtos-card">
                  <MDBCardTitle className="produto-title">
                    {produto.title}
                  </MDBCardTitle>
                  <MDBCardImage
                    src={produto.image}
                    alt={produto.title}
                    className="product-img"
                  />
                  <MDBCardBody>
                    <Link to="/produtos">
                      <MDBBtn className="produtos-btn">Ver mais</MDBBtn>
                    </Link>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBCol>

        <div className="text-center">
          <MDBBtn className="all-produtos-btn" href="#">
            Ver Todos os Produtos
          </MDBBtn>
        </div>
      </MDBContainer>

      {/* Shape Inferior */}
      <div className="shape-bottom">
        <img src={Bot} alt="Shape Bottom" className="img-fluid" />
      </div>
    </>
  );
};

export default Produtos;
