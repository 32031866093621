import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import '../../styles/about.css';  // Certifique-se de que o caminho está correto

const SecondSection = () => {
  return (
    <MDBContainer fluid className="second-section py-5">
      <MDBRow className="align-items-center">
        <MDBCol md="6">
          <MDBRow>
            <MDBCol md="6">
              <div className="image-placeholder small mb-3"></div>
              <div className="image-placeholder small"></div>
            </MDBCol>
            <MDBCol md="6">
              <div className="image-placeholder large"></div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
        <MDBCol md="6">
          <h2 className="title">Lorem Ipsum</h2>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e.
          </p>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e.
          </p>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SecondSection;
