import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import '../../styles/produto.css';

const MiddleSection = () => {
  return (
    <MDBContainer className="py-5 product-text-wrapper">
      <MDBRow>
        <MDBCol md="6" className="d-flex align-items-center mb-4">
          <div className="icon-box">
            <div className="icon-shadow"></div>
            <div className="icon" style={{ backgroundColor: '#000' }}></div>
          </div>
          <div className="ml-3">
            <h5 className="text-black">Lorem Ipsum</h5>
            <p className="text-muted">Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices.</p>
          </div>
        </MDBCol>
        <MDBCol md="6" className="d-flex align-items-center mb-4">
          <div className="icon-box">
            <div className="icon-shadow"></div>
            <div className="icon" style={{ backgroundColor: '#5A78F0' }}></div>
          </div>
          <div className="ml-3">
            <h5 className="text-blue">Lorem Ipsum</h5>
            <p className="text-muted">Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices.</p>
          </div>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="6" className="d-flex align-items-center mb-4">
          <div className="icon-box">
            <div className="icon-shadow"></div>
            <div className="icon" style={{ backgroundColor: '#FF6B6B' }}></div>
          </div>
          <div className="ml-3">
            <h5 className="text-red">Lorem Ipsum</h5>
            <p className="text-muted">Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices.</p>
          </div>
        </MDBCol>
        <MDBCol md="6" className="d-flex align-items-center mb-4">
          <div className="icon-box">
            <div className="icon-shadow"></div>
            <div className="icon" style={{ backgroundColor: '#3ECF8E' }}></div>
          </div>
          <div className="ml-3">
            <h5 className="text-green">Lorem Ipsum</h5>
            <p className="text-muted">Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices.</p>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default MiddleSection;
