import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaLeaf, FaBuilding, FaMountain } from 'react-icons/fa'; // Exemplo de ícones
import '../../styles/about.css';

const ThirdSection = () => {
  const spaces = [
    {
      color: '#FFC265',
      icon: <FaLeaf size={50} />,
      title: 'Natureza',
      text: 'Explorando a beleza natural em cada detalhe.'
    },
    {
      color: '#FF6B6B',
      icon: <FaBuilding size={50} />,
      title: 'Arquitetura',
      text: 'Design inovador que transforma espaços.'
    },
    {
      color: '#617DF8',
      icon: <FaMountain size={50} />,
      title: 'Aventura',
      text: 'Descubra a emoção das grandes aventuras.'
    }
  ];

  return (
    <MDBContainer fluid className="third-section pt-5 p-0">
      <MDBRow className='about-section-wrapper'>
        {spaces.map((space, index) => (
          <MDBCol key={index} md="4" className="text-center space-col">
            <div className="space-icon">{space.icon}</div>
            <h4 className="space-title">{space.title}</h4>
            <p className="space-text">{space.text}</p>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
};

export default ThirdSection;
