import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBBtn } from 'mdb-react-ui-kit';
import '../../styles/produto.css';
import Bot from '../../img/product/product-bot.png';

const TopSection = () => {
  return (
    <>
      <MDBContainer fluid className="top-section-container p-5">
        <MDBRow className="d-flex justify-content-center mb-4">
          <div className="custom-input-wrapper">
            <input type="text" id="searchBar" placeholder="Escolha o produto" className="custom-input" />
          </div>
        </MDBRow>
        <MDBRow className="d-flex align-items-center">
          <MDBCol md="5" className="d-flex justify-content-center">
            <div className="product-image-placeholder">
              <img src="https://fakeimg.pl/600x600" alt="Product" className="img-fluid product-image" />
            </div>
          </MDBCol>
          <MDBCol md="5" className="d-flex flex-column align-items-start">
            <MDBDropdown className="custom-dropdown mb-3">
              <MDBDropdownToggle className="dropdown-toggle-custom">Papel</MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem link>Item 1</MDBDropdownItem>
                <MDBDropdownItem link>Item 2</MDBDropdownItem>
                <MDBDropdownItem link>Item 3</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
            <MDBDropdown className="custom-dropdown mb-3">
              <MDBDropdownToggle className="dropdown-toggle-custom">Tamanho</MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem link>Item 1</MDBDropdownItem>
                <MDBDropdownItem link>Item 2</MDBDropdownItem>
                <MDBDropdownItem link>Item 3</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
            <MDBCol className='produto-button-wrapper'>
              <MDBCol md={6}>
                <MDBDropdown className="custom-dropdown">
                  <MDBDropdownToggle className="dropdown-toggle-custom">1x</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link>Item 1</MDBDropdownItem>
                    <MDBDropdownItem link>Item 2</MDBDropdownItem>
                    <MDBDropdownItem link>Item 3</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBCol>
              <MDBCol md={5} className="">
                <div className="action-buttons">
                  <MDBBtn color="primary" className="me-2 custom-btn">E-mail</MDBBtn>
                  <MDBBtn color="success" className="custom-btn">WhatsApp</MDBBtn>
                </div>
              </MDBCol>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className='shape-bottom'>
        <img src={Bot} alt='Shape Bottom' className='img-fluid' />
      </div>
    </>
  );
};

export default TopSection;
