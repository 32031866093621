import React from 'react';
import '../styles/svgs.css'; // Arquivo CSS onde você controlará as cores e estilos

const LogoPdv = () => {
    return (
        <svg width="357" height="422" viewBox="0 0 357 422" fill="none" xmlns="http://www.w3.org/2000/svg" className="my-svg">
            <g filter="url(#filter0_d_315_1837)">
                <path d="M268.484 341.677L276.302 357.313L285.061 372.417C240.229 398.308 189.196 403.568 142.892 391.166C96.5879 378.763 55.0134 348.674 29.147 303.891L28.8574 303.312C3.2564 258.624 -1.95549 207.808 10.3986 161.722C22.801 115.418 52.89 73.8434 97.6737 47.9769L115.192 78.2349C78.7565 99.2755 54.2895 133.08 44.2036 170.77C34.0934 208.46 38.3643 249.986 59.3567 286.397H59.3808C80.4214 322.808 114.226 347.275 151.916 357.385C189.606 367.471 231.108 363.224 267.519 342.208L268.484 341.725V341.677Z" className="path1" />
                <path d="M256.421 322.399C287.427 304.495 308.251 275.709 316.841 243.666C325.431 211.598 321.787 176.273 303.883 145.267C285.98 114.261 257.194 93.4377 225.126 84.8477C193.082 76.2577 157.733 79.9012 126.727 97.805L112.708 73.5311C150.422 51.7425 193.396 47.3269 232.389 57.7748C271.381 68.2227 306.393 93.5342 328.157 131.248C349.922 168.962 354.362 211.936 343.914 250.929C333.466 289.921 308.154 324.933 270.44 346.697L256.421 322.423V322.399Z" className="path2" />
                <path d="M256.42 322.398C287.426 304.494 308.249 275.708 316.839 243.664C325.429 211.597 321.786 176.272 303.882 145.266C303.544 144.711 303.23 144.132 302.892 143.577L327.166 129.558C327.504 130.113 327.818 130.668 328.156 131.247C349.92 168.961 354.36 211.935 343.912 250.927C333.464 289.92 308.153 324.931 270.439 346.696L256.42 322.422V322.398Z" className="path3" />
                <path d="M262.016 330.095C228.742 349.302 190.835 353.211 156.427 343.994C122.019 334.776 91.1575 312.433 71.9265 279.159C52.7197 245.885 48.8108 207.978 58.0281 173.57C67.2455 139.161 89.5891 108.3 122.863 89.0693L143.445 124.732C120.016 138.269 104.284 160.009 97.793 184.211C91.3022 208.436 94.0529 235.123 107.589 258.552C121.126 281.982 142.866 297.714 167.068 304.205C191.293 310.696 217.98 307.945 241.41 294.408L261.992 330.071L262.016 330.095Z" className="path4" />
                <path d="M74.6305 3.74859C74.1479 2.90407 73.0863 2.63865 72.2417 3.09711C71.3972 3.57969 71.1077 4.64137 71.5903 5.48589L307.646 414.33C308.128 415.151 309.19 415.44 310.034 414.982C310.879 414.499 311.169 413.437 310.686 412.593L74.6305 3.74859Z" className="path5" />
                <path d="M5.90891 313.977C5.06439 314.459 4.77484 315.521 5.25743 316.366C5.74001 317.21 6.80169 317.5 7.64621 317.017L351.945 118.217C352.79 117.734 353.079 116.673 352.596 115.828C352.114 114.984 351.052 114.694 350.208 115.177L5.90891 313.977Z" className="path6" />
            </g>
            <defs>
                <filter id="filter0_d_315_1837" x="0" y="0.874512" width="356.83" height="420.324" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_315_1837" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_315_1837" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default LogoPdv;
