import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import '../../styles/depoimentos.css';
import Top from '../../img/cta/cta-top.png';

const Depoimentos = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJ2cddnVppzpQRm3wbf3KcEJo&key=YOUR_API_KEY`
        );
        const data = await response.json();
        setReviews(data.result.reviews || []);
      } catch (error) {
        console.error("Erro ao carregar os depoimentos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  return (
    <>
      <MDBContainer fluid className='depoimentos-section'>
        <MDBRow className='justify-content-center'>
          <MDBCol md='8' className='text-center depoimentos-title'>
            <h2>Depoimentos</h2>
            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lab</h4>
          </MDBCol>
        </MDBRow>
        <MDBRow className='justify-content-center'>
          <MDBCol md='12'>
            {loading ? (
              <p>Carregando depoimentos...</p>
            ) : reviews.length > 0 ? (
              <div className='depoimentos-carousel'>
                {reviews.map((review, index) => (
                  <div key={index} className='depoimento-item'>
                    <p className='depoimento-text'>"{review.text}"</p>
                    <p className='depoimento-author'>- {review.author_name}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className='depoimento-item'>
                <p className='depoimento-text'>"Nenhum depoimento encontrado"</p>
              </div>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default Depoimentos;
