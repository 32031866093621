import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Index from '../src/screen/index';
import Category from '../src/screen/category';
import About from '../src/screen/about';
import Galery from './screen/gallery';
import Produto from './screen/product';
import Products from './screen/products';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/categorias" element={<Category />} />
        <Route path="/sobrenos" element={<About />} />
        <Route path="/galeria" element={<Galery />} />
        <Route path="/produto" element={<Produto />} />
        <Route path="/produtos" element={<Products />} />
      </Routes>
    </Router>
  );
}

export default App;


