import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardImage } from 'mdb-react-ui-kit';
import { FaSearch } from 'react-icons/fa'; // Importa o ícone do pacote react-icons
import '../../styles/hero.css';

import { IoSearchCircle } from "react-icons/io5";

const Hero = () => {
  return (
    <MDBContainer fluid className='p-0 hero-section d-flex justify-content-center align-items-center flex-column'>
      <div className='banner-superior'>
        <img src='https://fakeimg.pl/1920x300' alt='Banner Superior' className='img-fluid' />
      </div>
      <MDBRow className='cards-horizontal d-flex justify-content-center pt-5'>
        {/* Card 1 */}
        <MDBCol md='2' className='mb-4 text-center card-wrapper'>
          <a href='/pagina1'>
            <MDBCard className='hero-card'>
              <MDBCardImage src='https://cdn-icons-png.flaticon.com/512/13274/13274359.png' alt='Imagem 1' position='top' />
            </MDBCard>
          </a>
          <h5 className='mt-2'>Material Gráfico</h5>
        </MDBCol>

        {/* Card 2 */}
        <MDBCol md='2' className='mb-4 text-center card-wrapper'>
          <a href='/pagina2'>
            <MDBCard className='hero-card'>
              <MDBCardImage src='https://cdn-icons-png.flaticon.com/512/13274/13274359.png' alt='Imagem 2' position='top' />
            </MDBCard>
          </a>
          <h5 className='mt-2'>Material para <br></br>Ponto de Venda</h5>
        </MDBCol>

        {/* Card 3 */}
        <MDBCol md='2' className='mb-4 text-center card-wrapper'>
          <a href='/pagina3'>
            <MDBCard className='hero-card'>
              <MDBCardImage src='https://cdn-icons-png.flaticon.com/512/13274/13274359.png' alt='Imagem 3' position='top' />
            </MDBCard>
          </a>
          <h5 className='mt-2'>Sacola</h5>
        </MDBCol>

        {/* Card 4 */}
        <MDBCol md='2' className='mb-4 text-center card-wrapper'>
          <a href='/pagina4'>
            <MDBCard className='hero-card'>
              <MDBCardImage src='https://cdn-icons-png.flaticon.com/512/13274/13274359.png' alt='Imagem 4' position='top' />
            </MDBCard>
          </a>
          <h5 className='mt-2'>Comunicação</h5>
        </MDBCol>

        {/* Card 5 */}
        <MDBCol md='2' className='mb-4 text-center card-wrapper'>
          <a href='/pagina5'>
            <MDBCard className='hero-card'>
              <MDBCardImage src='https://cdn-icons-png.flaticon.com/512/13274/13274359.png' alt='Imagem 5' position='top' />
            </MDBCard>
          </a>
          <h5 className='mt-2'>Brindes</h5>
        </MDBCol>
      </MDBRow>
      <MDBRow className='ai-inputhero'>
        <div className='input-pesquisar'>
          <IoSearchCircle  style={{ color: '#617DF8', fontSize: '50px' }}/> <input type='text' placeholder='Pesquisar itens' />
        </div>
      </MDBRow>

    </MDBContainer>
  );
};

export default Hero;
