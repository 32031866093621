import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../styles/category.css'; // O CSS correspondente
import Bot from '../../img/category/bottom-img.png';

const TopSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "0",
        customPaging: i => (
            <div className="slick-dot"></div>
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <>
            <div className="top-section">
                <h2 className="section-title">Cases de Sucesso</h2>
                <Slider {...settings}>
                    <div className="case-item">
                        <div className="case-image"></div>
                    </div>
                    <div className="case-item">
                        <div className="case-image"></div>
                    </div>
                    <div className="case-item">
                        <div className="case-image"></div>
                    </div>
                </Slider>
            </div>
            <div className='shape-bottom'>
                <img src={Bot} alt='Shape Bottom' className='img-fluid' />
            </div>
        </>
    );
};

export default TopSection;
