import React, { useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import '../../styles/category.css';

const MiddleSection = () => {
  const [activeCategory, setActiveCategory] = useState('Categoria 1');

  // Produtos mockados para cada categoria
  const productsMock = {
    'Categoria 1': ['Produto 1', 'Produto 2', 'Produto 3'],
    'Categoria 2': ['Produto 4', 'Produto 5', 'Produto 6'],
    'Categoria 3': ['Produto 7', 'Produto 8', 'Produto 9'],
    'Categoria 4': ['Produto 10', 'Produto 11', 'Produto 12'],
    'Categoria 5': ['Produto 13', 'Produto 14', 'Produto 15'],
  };

  const categories = Object.keys(productsMock);

  return (
    <MDBContainer className="middle-section py-5">
      <h2 className="section-title text-center mb-5">Categorias</h2>

      {/* Renderizar as abas de categorias */}
      <MDBRow className="mb-4 d-flex justify-content-center align-items-center flex-wrap">
        {categories.map((category, index) => (
          <MDBCol key={index} size={4} xs="6" sm="4" md="2" className="mb-3 d-flex justify-content-center">
            <MDBBtn
              className={`category-btn ${activeCategory === category ? 'active' : ''}`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </MDBBtn>
          </MDBCol>
        ))}
      </MDBRow>

      {/* Renderizar os produtos da categoria ativa */}
      <MDBRow>
        {productsMock[activeCategory].map((product, index) => (
          <MDBCol key={index} xs="12" sm="6" md="4" className="mb-4 d-flex flex-column align-items-center">
            <div className="category-box"></div>
            <p className="category-title mt-3">{product}</p>
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
  );
};

export default MiddleSection;
