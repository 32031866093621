import React from 'react';
import TopSection from '../components/product/topsection';
import MiddleSection from '../components/product/middlesection';
import BottomSection from '../components/product/bottomsection';
import Footer from '../components/footer'
import Navbar from '../components/navbar'

const Product = () => {
    return (
        <div>
            <Navbar />
            <TopSection />
            <MiddleSection />
            <BottomSection />
            <Footer />
        </div>
    );
};

export default Product;
