import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBBtn,
} from "mdb-react-ui-kit";
import "../styles/products.css";
import Bot from "../img/product/product-bot.png";
import blackIcon from "../img/product/black_icon.png";
import blueIcon from "../img/product/blue_icon.png";
import greenIcon from "../img/product/green_icon.png";
import redIcon from "../img/product/red_icon.png";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import Clients from '../components/clients';
import ImageCarousel from '../components/products/imageCarousel';

const Products = () => {
  return (
    <>
      <Navbar />
      {/* Seção Principal */}
      <MDBContainer fluid className="products-section">
        {/* Input de Seleção */}
        <div className="input-pai">
          <MDBRow className="product-selection-input">
            <div className="input-pesquisar">
              <select className="form-toggle">
                <option>Escolha o produto</option>
                <option>caderno</option>
                <option>Garrafa</option>
                <option>Banner</option>
              </select>
            </div>
          </MDBRow>
        </div>

        <MDBRow className="products-content">
          {/* Imagem */}
          <MDBCol md="4" className="product-image-col">
            <div className="product-image-wrapper">
              <img
                src="https://fakeimg.pl/300x300"
                alt="Product Placeholder"
                className="product-image"
              />
            </div>
          </MDBCol>

          {/* Formulário de Seleção */}
          <MDBCol md="6" className="product-form-col">
            <div className="product-card">
              <MDBCardBody>
                {/* Toggle para Tipo de Material */}
                <MDBRow className="form-row">
                  <select className="form-toggle">
                    <option>Tipo de Material</option>
                    <option>Papel</option>
                    <option>Plástico</option>
                  </select>
                </MDBRow>

                {/* Toggle para Tamanho */}
                <MDBRow className="form-row">
                  <select className="form-toggle">
                    <option>Tamanho</option>
                    <option>Pequeno</option>
                    <option>Médio</option>
                    <option>Grande</option>
                  </select>
                </MDBRow>

                {/* Toggle para Quantidade e Botões de Ação */}
                <MDBRow className="form-row-cta">
                  <MDBCol md="4 p-0">
                    <select className="form-toggle">
                      <option>1x</option>
                      <option>2x</option>
                      <option>3x</option>
                    </select>
                  </MDBCol>
                  <MDBCol md={8} className="d-flex flex-row align-items-center">
                    <MDBCol md="6" className="pe-3">
                      <MDBBtn className="produtos-btn">E-mail</MDBBtn>
                    </MDBCol>
                    <MDBCol md="6">
                      <MDBBtn className="produtos-btn">Whatsapp</MDBBtn>
                    </MDBCol>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      {/* Shape Inferior */}
      <div className="shape-bottom">
        <img src={Bot} alt="Shape Bottom" className="img-fluid" />
      </div>

      {/* Segunda Sessão - Ícones e Textos */}
      <MDBContainer className="icons-section">
        <MDBRow>
          <MDBCol md="6" className="icon-col">
            <img src={blackIcon} alt="Black Icon" className="icon-img" />
            <div>
              <h4 style={{ color: "#222324bd", fontWeight: "bold" }}>
                Lorem Ipsum
              </h4>
              <p>
                Aliquam erat volutpat. Integer malesuada turpis id fringilla
                suscipit. Maecenas ultrices.
              </p>
            </div>
          </MDBCol>
          <MDBCol md="6" className="icon-col">
            <img src={redIcon} alt="Red Icon" className="icon-img" />
            <div>
              <h4 style={{ color: "#FF5757", fontWeight: "bold" }}>
                Lorem Ipsum
              </h4>
              <p>
                Aliquam erat volutpat. Integer malesuada turpis id fringilla
                suscipit. Maecenas ultrices.
              </p>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol md="6" className="icon-col">
            <img src={blueIcon} alt="Blue Icon" className="icon-img" />
            <div>
              <h4 style={{ color: "#617DF8", fontWeight: "bold" }}>
                Lorem Ipsum
              </h4>
              <p>
                Aliquam erat volutpat. Integer malesuada turpis id fringilla
                suscipit. Maecenas ultrices.
              </p>
            </div>
          </MDBCol>
          <MDBCol md="6" className="icon-col">
            <img src={greenIcon} alt="Green Icon" className="icon-img" />
            <div>
              <h4 style={{ color: "#08CFB0", fontWeight: "bold" }}>
                Lorem Ipsum
              </h4>
              <p>
                Aliquam erat volutpat. Integer malesuada turpis id fringilla
                suscipit. Maecenas ultrices.
              </p>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <ImageCarousel />
      <Clients />

      {/* Seção Sobre Nós */}
      <MDBContainer fluid className="about-section">
        <MDBRow className="align-items-center">
          <MDBCol md="6" className="about-image-col">
            <img src="https://fakeimg.pl/300x300" alt="Sobre Nós" className="img-fluid" />
          </MDBCol>
          <MDBCol md="6" className="about-text-col">
            <h2 className="about-title">Sobre Nós</h2>
            <p className="about-description">
              Nós somos uma empresa dedicada a oferecer produtos de qualidade e
              soluções inovadoras para nossos clientes. Com uma equipe
              apaixonada pelo que faz, buscamos sempre proporcionar a melhor
              experiência, seja através de nossos produtos ou do nosso serviço
              de atendimento ao cliente.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </>
  );
};

export default Products;
