import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import '../../styles/about.css';  // Certifique-se de que o caminho está correto

const FirstSection = () => {
  return (
    <MDBContainer fluid className="first-section py-5">
      <MDBRow className="align-items-center">
        <MDBCol md="6" className="text-section">
          <h2 className="title">Lorem Ipsum</h2>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore e.
          </p>
        </MDBCol>
        <MDBCol md="6" className="image-section">
          <div className="image-placeholder"></div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default FirstSection;
