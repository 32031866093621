import React, { useState } from 'react';
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBIcon,
    MDBBtn,
    MDBContainer,
} from 'mdb-react-ui-kit';
import '../styles/navbar.css';
import LogoPdv from './svgs';
import Modal from './modal'; // Importar o novo modal
import { CiMenuKebab } from "react-icons/ci";

export default function App() {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => setShowModal(!showModal);

    return (
        <>
            <MDBNavbar expand='lg' className='navbar-custom'>
                <MDBContainer fluid>
                    <MDBNavbarBrand href='#'>
                        <MDBIcon fas icon="gem" /> <LogoPdv />
                    </MDBNavbarBrand>
                    <MDBNavbarNav className='navbar-nav-left'>
                        <MDBNavbarItem>
                            <MDBNavbarLink aria-current='page' href='/'>
                                Inicio
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink href='/categorias'>
                                Produtos
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink href='/galeria'>
                                Galeria
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <MDBNavbarLink href='/sobrenos'>
                                Sobre nós
                            </MDBNavbarLink>
                        </MDBNavbarItem>
                        {/* <MDBNavbarItem>
                            <MDBNavbarLink href='/blog'>
                                Blog
                            </MDBNavbarLink>
                        </MDBNavbarItem> */}
                    </MDBNavbarNav>
                    <MDBNavbarNav className='ml-auto d-none d-lg-flex justify-content-end'>
                        <MDBBtn color='primary' className='contact-btn'>Entre em Contato</MDBBtn>
                    </MDBNavbarNav>
                    <MDBNavbarNav className='w-auto'>
                        <MDBBtn className='menu-btn' color='primary' onClick={toggleModal}><CiMenuKebab /></MDBBtn>
                    </MDBNavbarNav>
                </MDBContainer>
            </MDBNavbar>

            <Modal show={showModal} handleClose={toggleModal}>
                <h2>NAVEGAÇÃO</h2>
                <MDBNavbarNav>
                    <MDBNavbarItem>
                        <MDBNavbarLink aria-current='page' href='/'>
                            Inicio
                        </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                        <MDBNavbarLink href='/categorias'>
                            Produtos
                        </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                        <MDBNavbarLink href='/galeria'>
                            Galeria
                        </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarItem>
                        <MDBNavbarLink href='/sobrenos'>
                            Sobre nós
                        </MDBNavbarLink>
                    </MDBNavbarItem>
                    <MDBNavbarBrand href='#' className='modal-logo'>
                        <LogoPdv />
                    </MDBNavbarBrand>
                    {/* <MDBNavbarItem>
                        <MDBNavbarLink href='/blog'>
                            Blog
                        </MDBNavbarLink>
                    </MDBNavbarItem> */}
                </MDBNavbarNav>
            </Modal>
        </>
    );
}
