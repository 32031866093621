import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { FaHome, FaEye, FaHeart } from 'react-icons/fa'; // Importa os ícones do react-icons
import '../../styles/sobreNos.css';

import AboutIcon from '../../img/about/about-icon.png';

const SobreNos = () => {
  return (
    <MDBContainer fluid className='sobre-nos-section'>
      <MDBRow className='justify-content-center'>
        <MDBCol md='8' className='pb-4 text-center sobre-title'>
          <h2>Sobre Nós</h2>
          <h4>Conheça mais sobre nossa empresa</h4>
        </MDBCol>
      </MDBRow>
      <MDBRow className='align-items-center'>
        <MDBCol md='6' className='sobre-img'>
          <img src={AboutIcon} alt='Sobre Nós' className='img-fluid' />
        </MDBCol>
        <MDBCol md='6' className='sobre-text-wrapper'>
          <MDBRow className='mb-3'>
            <MDBCol md='12' className='sobre-text'>
              <MDBCol md={2}>
                <FaHome size='3em' style={{ color: '#FFC265' }} /> {/* Ícone substituído */}
              </MDBCol>
              <MDBCol md={10} className='sobre-text-content'>
                <h5>Missão</h5>
                <p>Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices.</p>
              </MDBCol>
            </MDBCol>
          </MDBRow>
          <MDBRow className='mb-3'>
            <MDBCol md='12' className='sobre-text'>
              <MDBCol md={2}>
                <FaEye size='3em' style={{ color: '#FF5757' }} /> {/* Ícone substituído */}
              </MDBCol>
              <MDBCol md={10} className='sobre-text-content'>
                <h5>Visão</h5>
                <p>Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices.</p>
              </MDBCol>
            </MDBCol>
          </MDBRow>
          <MDBRow className='mb-3'>
            <MDBCol md='12' className='sobre-text'>
              <MDBCol md={2}>
                <FaHeart size='3em' style={{ color: '#617DF8' }} /> {/* Ícone substituído */}
              </MDBCol>
              <MDBCol md={10} className='sobre-text-content'>
                <h5>Valores</h5>
                <p>Aliquam erat volutpat. Integer malesuada turpis id fringilla suscipit. Maecenas ultrices.</p>
              </MDBCol>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default SobreNos;
