import React from 'react';
import Hero from '../components/index/hero';
import Produtos from '../components/index/produtos';
import SobreNos from '../components/index/sobreNos';
import Depoimentos from '../components/index/depoimentos';
import CTA from '../components/index/cta';
import Contato from '../components/index/contato';
import Navbar from '../components/navbar'
import Clients from '../components/clients'
import Footer from '../components/footer'
import { MDBContainer } from 'mdb-react-ui-kit';

const Index = () => {
  return (
    <MDBContainer fluid className='p-0 d-flex flex-column justify-center'>
      <Navbar />
      <Hero />
      <Produtos />
      <SobreNos />
      <Clients />
      <Depoimentos />
      <CTA />
      <Contato />
      <Footer />
    </MDBContainer>
  );
};

export default Index;