import React, { useState, useEffect } from 'react';
import '../styles/produto.css';
import logos from '../img/clients/logos.json';

const Ferramentas = () => {
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        // Verificar se todas as imagens estão carregadas
        let loadedImages = 0;
        logos.forEach(logo => {
            const img = new Image();
            img.src = require(`../img/clients/${logo}`);
            img.onload = () => {
                loadedImages += 1;
                if (loadedImages === logos.length) {
                    setImagesLoaded(true);
                }
            };
        });
    }, []);

    return (
        <div className="carousel-section">
            {imagesLoaded ? (
                <div className="carousel-container slider" style={{ "--width": "200px", "--height": "350px", "--quantity": logos.length }}>
                    <div className="list">
                        {logos.map((logo, index) => (
                            <div className="item" style={{ "--position": index + 1 }} key={index}>
                                <img src={require(`../img/clients/${logo}`)} alt={`${logo.split('.')[0]} Logo`} />
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <p>Carregando...</p>
            )}
        </div>
    );
}

export default Ferramentas;
