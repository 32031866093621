import React from 'react';
import TopSection from '../components/category/topsection';
import MiddleSection from '../components/category/middlesection';
// import BottomSection from '../components/category/bottomsection';
import Footer from '../components/footer'
import Navbar from '../components/navbar'
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBIcon,
    MDBBtn,
    MDBContainer
} from 'mdb-react-ui-kit';


const Product = () => {
    return (
        <MDBContainer fluid className='p-0 category-container'>
            <Navbar />
            <TopSection />
            <MiddleSection />
            <Footer />
        </MDBContainer>
    );
};

export default Product;
